import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Droppable, Draggable } from "react-beautiful-dnd";
import RosterContext from "../../../contexts/RosterContext";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import { DragIcon } from "./PersonnelHelpers";
import { useDraggable } from "../../hooks/useDraggable";
import RouteConstants from "../../../constants/RouteConstants";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import { withRouter } from "react-router-dom";
import PlayerLinkFormatter from "../../table/PlayerLinkFormatter";
import WBCAdminAccess from "../../protected/WBCAdminAccess";
import WBCAccessControl from "../../protected/WBCAccessControl";
import FinalizedStatusContext from "../../../contexts/FinalizedStatusContext";
import AuthContext from "../../../contexts/AuthContext";

const Container = styled.div`
  margin-right: 14px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

const Blank = styled.div`
  height: 24px;
`;

const Header = styled.div`
  height: 33px;
  padding-left: 8px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 33px;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
  background-color: ${props => props.theme["almost-white"]};
`;

const Input = styled.input`
  height: 32px;
  width: 100%;
  padding: 8px;
  font-family: Helvetica;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid ${props => props.theme["light-grey"]};
`;

const List = styled.div`
  height: 250px;
  overflow-y: auto;
  margin-bottom: 5px;
  border: 1px solid ${props => props.theme["light-grey"]};
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 35px;
  letter-spacing: normal;
  border-bottom: 1px solid ${props => props.theme["light-grey"]};
  background-color: ${props => (props.isDragging ? "#cbe2f9" : undefined)};
  box-shadow: ${props => (props.isDragging ? "0 1px 4px 0 rgba(0, 0, 0, 0.2)" : undefined)};

  :last-child {
    border-bottom: none;
  }

  :focus {
    outline: none;
  }
`;

const StaffDragIcon = styled(DragIcon)`
  display: flex;
  &&& {
    i {
      font-size: 1.508em;
    }
  }
`;

const Button = styled(WBCPrimaryButton)`
  width: 100%;
  height: 36px;
  line-height: 36px;
`;

const StaffList = withRouter(({ history }) => {
  // context(s) && state
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const [filterText, setFilterText] = useState("");

  // variable(s)
  const droppableId = "staff-list";
  const { isBOCadmin } = authContext.state;
  const { teamId } = selectedTeamContext.state.team;
  const { initialStaff, personnel } = rosterContext.state;
  const { staff, managers, coaches, trainers } = personnel;
  const { finalized } = finalizedStatusContext.state;

  // function(s)
  // go to new staff profile page
  const newStaffProfile = () => {
    const route = RouteConstants.PROFILE.replace(":playerId", RouteConstants.NEW_PROFILE_SUFFIX)
      .replace(":fedTeamId", teamId)
      .replace(":profileType", RouteConstants.STAFF);
    history.push(route);
  };

  // filter staff. if there's no value, reset the staff
  const filterStaff = e => {
    setFilterText(e.target.value);

    // find remaining staff
    const current = new Set([...managers, ...coaches, ...trainers].map(p => p.profileId));
    const remainingStaff = Object.keys(initialStaff)
      .filter(k => !current.has(parseInt(k)))
      .map(k => initialStaff[k]);

    // if there's a value, filter. else, reset
    if (e.target.value) {
      const query = e.target.value.toLowerCase();

      rosterContext.dispatch({
        type: "setStaff",
        staff: remainingStaff.filter(s => {
          const { firstName, lastName } = s.fullName;
          return firstName.toLowerCase().includes(query) || lastName.toLowerCase().includes(query);
        })
      });
    } else {
      rosterContext.dispatch({
        type: "setStaff",
        staff: remainingStaff
      });
    }
  };

  // effect-like hooks
  useDraggable(droppableId, "personnel");

  return (
    <Container>
      <Blank />
      <Header>Managers, Coaches & Trainers</Header>
      <Input placeholder="type to search" value={filterText} onChange={filterStaff} />
      <Droppable droppableId={droppableId} type="STAFF">
        {provided => (
          <WBCAccessControl
            input={isDragDisabled => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {staff.map((s, i) => (
                  <Draggable
                    isDragDisabled={isDragDisabled || (finalized && !isBOCadmin)}
                    key={s.profileId}
                    draggableId={`${droppableId}-${s.profileId}`}
                    index={i}
                  >
                    {(innerProvided, snapshot) => (
                      <ListItem
                        id={`${droppableId}-${s.profileId}`}
                        isDragging={snapshot.isDragging}
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        {!finalized || isBOCadmin ? (
                          <WBCAdminAccess>
                            <StaffDragIcon />
                          </WBCAdminAccess>
                        ) : null}
                        <div className="ml-2">
                          <PlayerLinkFormatter
                            row={s}
                            value={`${s.fullName.lastName}, ${s.fullName.firstName}`}
                            profileType={RouteConstants.STAFF}
                            displayStyle="inline-block"
                          />
                        </div>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          />
        )}
      </Droppable>
      <WBCAdminAccess>
        <Button onClick={newStaffProfile}>Create Coaching Staff Member</Button>
      </WBCAdminAccess>
    </Container>
  );
});

export default StaffList;

import React, { useRef, useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AuthContext from "../../../contexts/AuthContext";
import { ContextMenu, MenuButton, MenuIcon, MenuItem } from "../../elements/ContextMenu";
import MLBPlayerRequestApi from "../../../httpClients/MLBPlayerRequestApi";
import MLBPlayerRequestContext from "../../../contexts/MLBPlayerRequestContext";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import { Icon } from "best-common-react";
import { useAlert } from "../../hooks/useAlert";

const InactiveMenuIcon = styled(Icon)`
  color: ${props => props.theme.grey};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.grey}; // Keep the same color on hover
  }
`;

const MODAL_ACTIONS = {
  REQUEST_REQUIRED: 1,
  SUBMIT_TO_BOC: 2,
  SUBMIT_TO_CLUB: 3,
  APPROVE: 4,
  DENY: 5,
  CANCEL_REQUEST: 6,
  RESUBMIT_REQUEST: 7
};

const RequestActionMenuFormatter = ({ row }) => {
  const authContext = useContext(AuthContext);
  const { isFedUser, isBOCadmin, isBOCviewer, isTeamCoordinator } = authContext.state;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalAction, setModalAction] = useState(MODAL_ACTIONS.REQUEST_REQUIRED);
  const { playerRequests, setPlayerRequests } = useContext(MLBPlayerRequestContext);
  const { dispatch } = useContext(GlobalModalContext);
  const showAlert = useAlert();

  const toggleMenu = useCallback(value => setIsOpen(value !== undefined ? value : !isOpen), [isOpen, setIsOpen]);

  const handleSubmitToBOC = () => {
    setModalAction(MODAL_ACTIONS.SUBMIT_TO_BOC);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleSubmitToClub = () => {
    setModalAction(MODAL_ACTIONS.SUBMIT_TO_CLUB);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleClubDecision = decisionId => {
    setModalAction(decisionId);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleCancelRequest = () => {
    setModalAction(MODAL_ACTIONS.CANCEL_REQUEST);
    setIsOpen(!isOpen);
    setShowCancelModal(true);
  };

  const handleResubmitRequest = () => {
    setModalAction(MODAL_ACTIONS.RESUBMIT_REQUEST);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleUpdateStatus = (newStatus, showAlertBool = false, alertMessage) => {
    MLBPlayerRequestApi.updatePlayerRequestStatus(row?.profileId, newStatus)
      .then(res => {
        updatePlayerRequests(res, showAlertBool, alertMessage);
      })
      .catch(err => {
        showAlert(`ERROR: Could not update status to ${newStatus}. (${err.message})`, "danger");
      });
  };

  const handleResubmit = (showAlertBool = false, alertMessage) => {
    MLBPlayerRequestApi.resubmitPlayerRequest(row?.profileId)
      .then(res => {
        updatePlayerRequests(res, showAlertBool, alertMessage);
      })
      .catch(err => {
        showAlert(`ERROR: Could not resubmit. (${err.message})`, "danger");
      });
  };

  const updatePlayerRequests = (res, showAlertBool = false, alertMessage) => {
    const updatedPlayerRequests = playerRequests.map(request => {
      if (request.profileId === row.profileId) {
        return { ...request, requestStatus: res.requestStatus };
      }
      return request;
    });
    if (showAlertBool) {
      showAlert(alertMessage);
    }
    setPlayerRequests(updatedPlayerRequests);
  };

  // Function to handle cancel action in the modal
  const handleConfirmCancelModal = () => {
    handleUpdateStatus("Cancelled", true, "Cancelled Request");
    setShowCancelModal(false);
  };

  // Function to handle confirm action in the modal
  const handleConfirmModal = () => {
    if (modalAction !== MODAL_ACTIONS.RESUBMIT_REQUEST) {
      const newStatus =
        modalAction === MODAL_ACTIONS.SUBMIT_TO_BOC
          ? "Submitted to BOC"
          : modalAction === MODAL_ACTIONS.SUBMIT_TO_CLUB
          ? "Submitted to Club"
          : modalAction === MODAL_ACTIONS.APPROVE
          ? "Approved"
          : modalAction === MODAL_ACTIONS.DENY
          ? "Denied"
          : null;
      handleUpdateStatus(newStatus, true, "Submitted");
    } else {
      handleResubmit(true, "Submitted");
    }
    setShowConfirmModal(false);
  };

  const requestRequired = row?.requestStatus === "Request Required";

  const submittedToBOC = row?.requestStatus === "Submitted to BOC";

  const submittedToClub = row?.requestStatus === "Submitted to Club";

  const canCancelRequest = submittedToBOC || submittedToClub;

  const canResubmitRequest = isBOCadmin && row?.requestStatus === "Cancelled";

  const rowHasActions =
    ((requestRequired || submittedToBOC || submittedToClub) && !(isFedUser || isBOCviewer || isTeamCoordinator)) ||
    canResubmitRequest;

  useEffect(() => {
    if (showConfirmModal) {
      const modalDetails =
        modalAction === MODAL_ACTIONS.SUBMIT_TO_BOC
          ? { headerText: "Submit Request", content: "submit a", submitBtnTxt: "Submit Request to BOC" }
          : modalAction === MODAL_ACTIONS.SUBMIT_TO_CLUB
          ? { headerText: "Submit Request", content: "submit a", submitBtnTxt: "Submit Request to Club" }
          : modalAction === MODAL_ACTIONS.APPROVE
          ? { headerText: "Approve Request", content: "approve the", submitBtnTxt: "Approve" }
          : modalAction === MODAL_ACTIONS.DENY
          ? { headerText: "Deny Request", content: "deny the", submitBtnTxt: "Deny" }
          : modalAction === MODAL_ACTIONS.RESUBMIT_REQUEST
          ? { headerText: "Resubmit Request", content: "resubmit the", submitBtnTxt: "Resubmit to BOC" }
          : null;
      dispatch({
        type: "openConfirmSubmitModal",
        headerText: modalDetails.headerText,
        content: `Are you sure you want to ${modalDetails.content} request for the following player?`,
        updateStatusButtonText: modalDetails.submitBtnTxt,
        onConfirmRequestFn: handleConfirmModal,
        profileId: row?.profileId,
        submitProfile: row
      });
      setShowConfirmModal(false);
    }
  }, [showConfirmModal]);

  useEffect(() => {
    if (showCancelModal) {
      dispatch({
        type: "openConfirmCancelModal",
        updateStatusButtonText: "Cancel Request",
        onConfirmUpdateFn: handleConfirmCancelModal,
        profileId: row?.profileId
      });
      setShowCancelModal(false);
    }
  }, [showCancelModal]);

  return rowHasActions ? (
    <>
      <MenuButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon iconName="fa-ellipsis-v" />
      </MenuButton>
      <ContextMenu targetRef={ref} isOpen={isOpen} toggleMenu={toggleMenu}>
        {requestRequired && <MenuItem onClick={handleSubmitToBOC}>Submit to BOC</MenuItem>}
        {submittedToBOC && <MenuItem onClick={handleSubmitToClub}>Submit to Club</MenuItem>}
        {submittedToClub && <MenuItem onClick={() => handleClubDecision(MODAL_ACTIONS.APPROVE)}>Approve</MenuItem>}
        {submittedToClub && <MenuItem onClick={() => handleClubDecision(MODAL_ACTIONS.DENY)}>Deny</MenuItem>}
        {canCancelRequest && <MenuItem onClick={handleCancelRequest}>Cancel Request</MenuItem>}
        {canResubmitRequest && <MenuItem onClick={handleResubmitRequest}>Resubmit to BOC</MenuItem>}
      </ContextMenu>
    </>
  ) : (
    <>
      <MenuButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <InactiveMenuIcon iconName="fa-ellipsis-v" disabled={true} />
      </MenuButton>
    </>
  );
};

RequestActionMenuFormatter.propTypes = {
  row: PropTypes.object
};

export default RequestActionMenuFormatter;

import Moment from "moment";
import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import PositionConstants from "../../constants/PositionConstants";
import { QUALIFYING } from "../../constants/TournamentTypeConstants";
import FinalizedStatusContext from "../../contexts/FinalizedStatusContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import RosterContext from "../../contexts/RosterContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import InterestListContext from "../../contexts/InterestListContext";
import MainRosterApi from "../../httpClients/MainRosterApi";
import QualifyingRosterApi from "../../httpClients/QualifyingRosterApi";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import WBCContent from "../elements/WBCContent";
import { useAlert } from "../hooks/useAlert";
import { useError } from "../hooks/useError";
import { useFinalizedStatus } from "../hooks/useFinalizedStatus";
import AvailableRoster from "./AvailableRoster";
import FinalRoster from "./FinalRoster";
import ProvisionalRoster from "./ProvisionalRoster";
import { sortPlayers } from "./RosterSortHelper";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";

// TODO: Remove staff code from this file once new Coaching Staff page is complete
//       Keeping it here for now to have a reference for the new page

const FinalizeWrapper = styled.div`
  margin-bottom: 16px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
`;

const FinalizedText = styled.span`
  display: inline-block;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.black};
  margin-right: 6px;
`;

// const validStatuses = new Set([1, 2]);

// Check if a Staff Profile has a Tournament Assignment and Position Category for a given Tournament
// const isAssignedToTournament = (staffProfile, tournamentTypeId, positionCategory) => {
//   const assignment = staffProfile.tournaments[tournamentTypeId];
//   const category = staffProfile.categories[tournamentTypeId];
//   const assignmentValid = assignment && validStatuses.has(assignment.status);
//   const positionValid = category && category.category === positionCategory;
//   return assignmentValid && positionValid;
// };

// Check if a Staff Profile is available oto be dragged to a position
// const isAvailableStaff = (staffProfile, tournamentType, isManager, isCoach, isTrainer, isMainTournamentEnabled) => {
//   const noPosition = !isManager && !isCoach && !isTrainer;
//   if (noPosition) {
//     // If we're Qualifying then make sure we have no position
//     if (!isMainTournamentEnabled && tournamentType === QUALIFYING) {
//       const category = staffProfile.categories[1];
//       return staffProfile.staffPosition === null || !category;
//     } else if (tournamentType === QUALIFYING) {
//       // If we're Qualifying and mainTournament enabled then just check that we don't have an assignment
//       const assignment = staffProfile.tournaments[1];
//       const assignmentValid = assignment && validStatuses.has(assignment.status);
//       return !assignmentValid;
//     } else {
//       // If we're Main then just check that we don't have an assignment
//       const assignment = staffProfile.tournaments[2];
//       const assignmentValid = assignment && validStatuses.has(assignment.status);
//       return !assignmentValid;
//     }
//   }
//   return false;
// };

// Check that a Staff Profile is on this Team for this Tournament
// const isTeamStaff = (staffProfile, tournamentType, positionConstant, isMainTournamentEnabled) => {
//   const position = positionConstant.value;
//   const positionCategory = positionConstant.category;
//   const typeToCheck = tournamentType === QUALIFYING ? 1 : 2;
//   // If we're Qualifying then check profile columns
//   if (!isMainTournamentEnabled && tournamentType === QUALIFYING) {
//     const profileCheck =
//       staffProfile.staffPosition === position &&
//       staffProfile.tournamentTypeId === typeToCheck &&
//       validStatuses.has(staffProfile.tournamentStatusId);
//     return profileCheck && isAssignedToTournament(staffProfile, typeToCheck, positionCategory);
//   }

//   // If we're Main then check Tournament Assignments and Position Categories
//   return isAssignedToTournament(staffProfile, typeToCheck, positionCategory);
// };

const SharedRosterForm = () => {
  // context(s)
  const rosterContext = useContext(RosterContext);
  const globalModalContext = useContext(GlobalModalContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const interestContext = useContext(InterestListContext);
  const tournamentType = useContext(TournamentTypeContext);

  // variable(s)
  const { isOpen } = globalModalContext.state;
  const { teams } = selectedTeamContext.state;
  const { teamId: fedTeamId, finalizedStatus } = selectedTeamContext.state.team;
  const { dispatch: globalDispatch } = globalModalContext;
  const { dispatch: selectedDispatch } = selectedTeamContext;
  const { dispatch: navigationDispatch } = tournamentFormContext;
  const { finalized } = finalizedStatusContext.state;
  const {
    dispatch: rosterDispatch,
    state: { provisional, final, personnel, uniformUpdates }
  } = rosterContext;
  const { dispatch: interestDispatch } = interestContext;
  const isMainTournamentEnabled = useIsFeatureEnabled("mainTournament");

  // function(s)
  const showAlert = useAlert();
  const showErrors = useError();

  // load roster
  const loadRoster = useCallback(() => {
    const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;
    if (fedTeamId) {
      // load roster && staff
      const promises = [
        api.getAvailableRoster(fedTeamId),
        api.getProvisionalRoster(fedTeamId),
        api.getFinalRoster(fedTeamId)
        // api.getTeamStaff(fedTeamId)
      ];

      Promise.all(promises).then(([available, provisional, final, staff]) => {
        // const { staff: people } = staff;
        const { players: finalRoster } = final;
        const provisionalWithFinal = provisional.players.map(p => ({ ...p, inFinalRoster: false })).concat(finalRoster);
        // people.forEach(p => {
        //   p.isCoach = isTeamStaff(p, tournamentType, PositionConstants.STAFF.COACH, isMainTournamentEnabled);
        //   p.isManager = isTeamStaff(p, tournamentType, PositionConstants.STAFF.MANAGER, isMainTournamentEnabled);
        //   p.isTrainer = isTeamStaff(p, tournamentType, PositionConstants.STAFF.TRAINER, isMainTournamentEnabled);
        //   p.noPosition = isAvailableStaff(
        //     p,
        //     tournamentType,
        //     p.isManager,
        //     p.isCoach,
        //     p.isTrainer,
        //     isMainTournamentEnabled
        //   );
        // });
        rosterDispatch({
          type: "setRoster",
          available: sortPlayers("lastName", "ASC", available),
          provisional: sortPlayers("lastName", "ASC", provisionalWithFinal),
          final: {
            catchers: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.CATCHER)
            ),
            pitchers: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.PITCHER)
            ),
            infield: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.INFIELD)
            ),
            outfield: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.OUTFIELD)
            )
          }
          // personnel: {
          //   staff: people.filter(p => p.noPosition),
          //   managers: people.filter(p => p.isManager),
          //   coaches: people.filter(p => p.isCoach),
          //   trainers: people.filter(p => p.isTrainer)
          // }
        });
      });
    }
  }, [tournamentType, fedTeamId, rosterDispatch]);

  // on save
  const onSave = useCallback(
    skipSuccessAlert => {
      // save variables
      // const { managers, coaches, trainers } = personnel;
      const { catchers, pitchers, infield, outfield } = final;
      const rosterUpdates = {
        provisionalRoster: playersToRosterUpdates(provisional.players.filter(p => !p.inFinalRoster)),
        catchers: playersToRosterUpdates(catchers),
        pitchers: playersToRosterUpdates(pitchers),
        infield: playersToRosterUpdates(infield),
        outfield: playersToRosterUpdates(outfield)
      };
      // const staffUpdates = {
      //   managers: managers.map(m => m.profileId),
      //   coaches: coaches.map(c => c.profileId),
      //   trainers: trainers.map(t => t.profileId)
      // };

      const uniUpdates = Object.entries(uniformUpdates).map(uu => {
        const [profileId, sizes] = uu;
        return {
          profileId,
          uniformNumber: sizes.uniformNumber,
          pants: sizes.pants,
          jersey: sizes.jersey,
          hat: sizes.hat
        };
      });

      const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;

      const requests = [
        api.updateTournamentRoster(fedTeamId, rosterUpdates)
        // api.updateStaffStatus(fedTeamId, staffUpdates)
      ];

      return new Promise((resolve, reject) => {
        Promise.all(requests)
          .then(() => {
            interestDispatch({
              type: "setRosterSaved",
              rosterSaved: true
            });
            api.updateUniforms(fedTeamId, uniUpdates).then(() => {
              if (skipSuccessAlert !== true) {
                showAlert("Saved");
              }
              resolve();
            });
          })
          .catch(({ response }) => {
            /**
             * BOC Admins won't get the roster finalized error.
             * any errors that come here should be unoverridable errors
             */
            const closeCallback = message => {
              return () => {
                if (message.indexOf("Roster already finalized") === -1) return;
                WBCTeamApi.getFederationTeamByTeamId(fedTeamId).then(({ finalizedStatus }) => {
                  const index = teams.findIndex(t => t.teamId === fedTeamId);
                  const team = { ...teams[index], finalizedStatus };
                  selectedDispatch({
                    type: "setTeams",
                    teams: Object.assign([...teams], { [index]: team }),
                    team
                  });
                  loadRoster();
                });
              };
            };

            showErrors(response.data.message, null, closeCallback(response.data.message));
            reject();
          });
      });
    },
    [
      tournamentType,
      //      personnel,
      provisional,
      final,
      uniformUpdates,
      fedTeamId,
      teams,
      showAlert,
      showErrors,
      loadRoster,
      interestDispatch,
      selectedDispatch
    ]
  );

  const onWarning = useCallback(
    afterSaveFn => {
      return new Promise((resolve, reject) => {
        if (finalized && !isOpen) {
          const saveFn = () => {
            onSave(!!afterSaveFn).then(() => {
              globalDispatch({ type: "closeModal" });
              if (afterSaveFn) {
                afterSaveFn();
              }
              resolve();
            });
          };

          globalDispatch({
            type: "openWarningModal",
            content: "This roster has already been finalized. Do you still wish to save?",
            saveFn
          });
        } else {
          onSave(!!afterSaveFn).then(() => {
            if (afterSaveFn) {
              afterSaveFn();
            }
            resolve();
          });
        }
      });
    },
    [globalDispatch, finalized, isOpen, onSave]
  );

  const playersToRosterUpdates = players => {
    const rosterUpdates = [];
    players.forEach((player, i) => {
      rosterUpdates.push({ profileId: player.profileId, playerPositionCategoryIndex: i });
    });
    return rosterUpdates;
  };

  // effects
  // load roster
  useEffect(loadRoster, [loadRoster]);

  // set on save functionality
  useEffect(() => navigationDispatch({ type: "setOnSave", onSave: onWarning }), [navigationDispatch, onWarning]);

  // set on cancel
  useEffect(() => navigationDispatch({ type: "setOnCancel", onCancel: loadRoster }), [navigationDispatch, loadRoster]);

  // get finalized status
  useFinalizedStatus(tournamentType);

  return (
    <WBCContent>
      {finalized && finalizedStatus ? (
        <FinalizeWrapper>
          <FinalizedText>Finalized</FinalizedText>
          {Moment(finalizedStatus[`${tournamentType}RosterFinalizedDate`]).format("MM/DD/YYYY - hh:mm a")} by{" "}
          {finalizedStatus[`${tournamentType}RosterFinalizedUser`]}
        </FinalizeWrapper>
      ) : null}
      <AvailableRoster tournamentType={tournamentType} />
      <ProvisionalRoster tournamentType={tournamentType} />
      <FinalRoster tournamentType={tournamentType} />
    </WBCContent>
  );
};

export default SharedRosterForm;

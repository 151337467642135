import RosterApi from "./RosterApi";

const prefix = "main";

export default class MainRosterApi extends RosterApi {
  static getAvailableRoster = fedTeamId => super.getAvailableRoster(prefix, fedTeamId);
  static getProvisionalRoster = fedTeamId => super.getProvisionalRoster(prefix, fedTeamId);
  static getFinalRoster = fedTeamId => super.getFinalRoster(prefix, fedTeamId);
  static getTeamStaff = fedTeamId => super.getTeamStaff(prefix, fedTeamId);

  static updateTournamentRoster = (fedTeamId, rosterUpdates) =>
    super.updateTournamentRoster(prefix, fedTeamId, rosterUpdates);
  static updateStaffStatus = (fedTeamId, statusUpdates) => super.updateStaffStatus(prefix, fedTeamId, statusUpdates);
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { BoldText, DraggableRow } from "./PersonnelHelpers";

const Cell = styled.div`
  padding: 8px;
`;

const Placeholder = ({ index, dragId }) => (
  <Draggable isDragDisabled draggableId={dragId} index={index}>
    {provided => {
      const { innerRef, draggableProps, dragHandleProps } = provided;
      return (
        <DraggableRow ref={innerRef} {...draggableProps} {...dragHandleProps}>
          <Cell>
            <BoldText>{index + 1}</BoldText>
          </Cell>
        </DraggableRow>
      );
    }}
  </Draggable>
);

Placeholder.propTypes = {
  index: PropTypes.number,
  dragId: PropTypes.string
};

export default React.memo(Placeholder);

import React, { useReducer } from "react";
import PropTypes from "prop-types";
import RosterContext from "../contexts/RosterContext";

// TODO: Remove staff code from this file once new Coaching Staff page is complete
//       Keeping it here for now to have a reference for the new page

const initialState = {
  idToDataMap: {
    // "staff-list": "staff",
    // "manager-list": "managers",
    // "coach-list": "coaches",
    // "trainer-list": "trainers",
    "catcher-list": "catchers",
    "pitcher-list": "pitchers",
    "infield-list": "infield",
    "outfield-list": "outfield",
    "player-table": "players"
  },
  availablePlayers: [],
  // used to reset the staff filter
  initialStaff: {},
  /* these state objects are in objects in order to satisfy `useDraggable`
     as currently constructed */
  // personnel: {
  //   staff: [],
  //   managers: [],
  //   coaches: [],
  //   trainers: []
  // },
  provisional: {
    players: []
  },
  final: {
    catchers: [],
    pitchers: [],
    infield: [],
    outfield: []
  },
  uniformUpdates: {},
  availableIsOpen: false,
  provisionalIsOpen: false,
  staffIsOpen: false,
  playersIsOpen: false,
  finalIsOpen: false
};

const RosterContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      // draggable cases
      case "updatePeople":
        return { ...state, [action.key]: { ...state[action.key], [action.source]: action.list } };
      case "updatePlayers":
        return { ...state, provisional: { players: action.players } };
      case "updateAvailablePlayers":
        return { ...state, availablePlayers: action.players };
      case "updateMultiple":
        // case where moving a provisional roster to final roster
        if (action.key === "provisional") {
          const { players, ...rest } = action.data;
          return {
            ...state,
            provisional: { players },
            final: { ...state.final, ...rest }
          };
        }

        // every other case
        return { ...state, [action.key]: { ...state[action.key], ...action.data } };
      // initializer
      case "setRoster":
        return {
          ...state,
          availablePlayers: action.available,
          provisional: { players: action.provisional },
          final: action.final,
          // personnel: action.personnel,
          // initialStaff: action.personnel.staff.reduce(
          //   (pv, s) => ({
          //     ...pv,
          //     [s.profileId]: s
          //   }),
          //   {}
          // ),
          uniformUpdates: {}
        };
      // misc
      case "addToProvisionalRoster":
        return {
          ...state,
          provisional: {
            players: [...state.provisional.players, action.player]
          }
        };
      case "removeFromFinalRoster":
        // set provisional player `isInFinalRoster` to false
        const { source, list, profileId } = action;
        return {
          ...state,
          final: { ...state.final, [source]: list },
          provisional: {
            players: state.provisional.players.map(p => ({
              ...p,
              inFinalRoster: p.profileId === profileId ? false : p.inFinalRoster
            }))
          }
        };
      // case "setStaff":
      //   return { ...state, personnel: { ...state.personnel, staff: action.staff } };
      case "setPlayerDocFlags":
        //Players
        const provisionalPlayers = [...state.provisional.players];
        const availablePlayers = [...state.availablePlayers];

        const provisionalPlayer = provisionalPlayers.find(player => {
          return player.profileId === action.profileId;
        });
        if (provisionalPlayer) {
          provisionalPlayer.hasPpa = action.hasPpa;
          provisionalPlayer.hasOtherDocs = action.hasOtherDocs;
        }
        const availablePlayer = availablePlayers.find(player => {
          return player.profileId === action.profileId;
        });
        if (availablePlayer) {
          availablePlayer.hasPpa = action.hasPpa;
          availablePlayer.hasOtherDocs = action.hasOtherDocs;
        }

        //Staff
        // const allStaff = [...state.personnel.staff];
        // const managers = [...state.personnel.managers];
        // const coaches = [...state.personnel.coaches];
        // const trainers = [...state.personnel.trainers];

        // const staff = allStaff.find(s => {
        //   return s.profileId === action.profileId;
        // });
        // if (staff) {
        //   staff.hasNppa = action.hasNppa;
        // }
        // const manager = managers.find(s => {
        //   return s.profileId === action.profileId;
        // });
        // if (manager) {
        //   manager.hasNppa = action.hasNppa;
        // }
        // const coach = coaches.find(s => {
        //   return s.profileId === action.profileId;
        // });
        // if (coach) {
        //   coach.hasNppa = action.hasNppa;
        // }
        // const trainer = trainers.find(s => {
        //   return s.profileId === action.profileId;
        // });
        // if (trainer) {
        //   trainer.hasNppa = action.hasNppa;
        // }

        return {
          ...state,
          provisional: { players: provisionalPlayers },
          availablePlayers: availablePlayers
          // personnel: {
          //   staff: allStaff,
          //   managers: managers,
          //   coaches: coaches,
          //   trainers: trainers
          // }
        };
      case "setUniform": {
        const { profileId, field, value } = action;
        const updatedPlayer = {};
        updatedPlayer[profileId] = { ...state.uniformUpdates[profileId], [field]: value };
        return {
          ...state,
          availablePlayers: state.availablePlayers.map(p => ({
            ...p,
            [field]: p.profileId === profileId ? value : p[field]
          })),
          provisional: {
            players: state.provisional.players.map(p => ({
              ...p,
              [field]: p.profileId === profileId ? value : p[field]
            }))
          },
          // personnel: {
          //   staff: state.personnel.staff.map(s => ({
          //     ...s,
          //     uniform: {
          //       ...s.uniform,
          //       [field]: s.profileId === profileId ? value : s.uniform ? s.uniform[field] : ""
          //     }
          //   })),
          //   managers: state.personnel.managers.map(s => ({
          //     ...s,
          //     uniform: {
          //       ...s.uniform,
          //       [field]: s.profileId === profileId ? value : s.uniform ? s.uniform[field] : ""
          //     }
          //   })),
          //   coaches: state.personnel.coaches.map(s => ({
          //     ...s,
          //     uniform: {
          //       ...s.uniform,
          //       [field]: s.profileId === profileId ? value : s.uniform ? s.uniform[field] : ""
          //     }
          //   })),
          //   trainers: state.personnel.trainers.map(s => ({
          //     ...s,
          //     uniform: {
          //       ...s.uniform,
          //       [field]: s.profileId === profileId ? value : s.uniform ? s.uniform[field] : ""
          //     }
          //   }))
          // },
          uniformUpdates: { ...state.uniformUpdates, ...updatedPlayer }
        };
      }
      case "updatePosition": {
        const { profileId, positionId, position } = action;
        return {
          ...state,
          availablePlayers: state.availablePlayers.map(p => ({
            ...p,
            positionId: p.profileId === profileId ? positionId : p.positionId,
            position: p.profileId === profileId ? position : p.position
          })),
          provisional: {
            players: state.provisional.players.map(p => ({
              ...p,
              positionId: p.profileId === profileId ? positionId : p.positionId,
              position: p.profileId === profileId ? position : p.position
            }))
          }
        };
      }
      case "setAvailableIsOpen":
        return { ...state, availableIsOpen: action.isOpen };
      case "setProvisionalIsOpen":
        return { ...state, provisionalIsOpen: action.isOpen };
      case "setStaffIsOpen":
        return { ...state, staffIsOpen: action.isOpen };
      case "setPlayersIsOpen":
        return { ...state, playersIsOpen: action.isOpen };
      case "setFinalIsOpen":
        return { ...state, finalIsOpen: action.isOpen };
      case "closeSections":
        return {
          ...state,
          availableIsOpen: false,
          provisionalIsOpen: false,
          staffIsOpen: false,
          playersIsOpen: false,
          finalIsOpen: false
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return <RosterContext.Provider value={{ state, dispatch }}>{children}</RosterContext.Provider>;
};

RosterContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default RosterContextProvider;

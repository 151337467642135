import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { Droppable } from "react-beautiful-dnd";
import { PersonnelHeader } from "./PersonnelHelpers";
import RosterContext from "../../../contexts/RosterContext";
import { useDraggable } from "../../hooks/useDraggable";
import PersonnelRow from "./PersonnelRow";
import Placeholder from "./Placeholder";
import AuthContext from "../../../contexts/AuthContext";
import WBCAccessControl from "../../protected/WBCAccessControl";
import FinalizedStatusContext from "../../../contexts/FinalizedStatusContext";
import { useCellResize } from "../../hooks/useCellResize";

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const MIN_COLUMN_WIDTH = 70;

/**
 * A generic personnel list for managers, coaches, and trainers
 */
const PersonnelList = ({ droppableId, header, settings, className }) => {
  // context(s)
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);

  // variable(s)
  const { minimum, maximum } = settings;
  const { isBOCadmin } = authContext.state;
  const { personnel: values, idToDataMap: hashTable } = rosterContext.state;
  const rows = values[hashTable[droppableId]];
  const remaining = _.range(rows.length, rows.length + Math.max(maximum - rows.length, 0));
  const { finalized } = finalizedStatusContext.state;

  // pixel width
  const pixelWidth = useCellResize("personnel-columns", 2, 598, MIN_COLUMN_WIDTH, rows.length);

  // effect(s)
  useDraggable(droppableId, "personnel");

  return (
    <>
      <PersonnelHeader text={header} minimum={minimum} invalid={rows.length < minimum} />
      <WBCAccessControl
        input={isDropDisabled => (
          <Droppable
            type="STAFF"
            droppableId={droppableId}
            isDropDisabled={isDropDisabled || (finalized && !isBOCadmin) || rows.length >= maximum}
          >
            {provided => (
              <Wrapper ref={provided.innerRef} className={className} {...provided.droppableProps}>
                {rows.map((row, i) => (
                  <PersonnelRow
                    key={row.profileId}
                    row={row}
                    index={i}
                    pixelWidth={pixelWidth}
                    droppableId={droppableId}
                  />
                ))}
                {remaining.map(i => (
                  <Placeholder key={`${droppableId}-p${i}`} dragId={`${droppableId}-p${i}`} index={i} />
                ))}
                {provided.placeholder}
              </Wrapper>
            )}
          </Droppable>
        )}
      />
    </>
  );
};

PersonnelList.propTypes = {
  droppableId: PropTypes.string.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  settings: PropTypes.object
};

export default PersonnelList;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "./WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import WBCDataTable from "../data-table/WBCDataTable";

const BodyContent = styled.div`
  text-align: left;
  margin-top: 10px;
  margin-bottom: 100px;
  margin-left: 10px;
  height: 100px;
  width: 100%;
`;

const ModalTable = styled(WBCDataTable)`
  margin-top: 20px;
`;

const ConfirmSubmitRequestModalContent = ({ content, submitProfile }) => {
  let columns = [
    { name: "Last", key: "lastName", width: 110 },
    { name: "First", key: "firstName", width: 110 },
    { name: "Org", key: "org", width: 90 },
    { name: "Club", key: "club", width: 220 }
  ];

  let data = [submitProfile];

  return (
    <>
      <BodyContent>
        {content}
        <ModalTable useBCR columns={columns} data={data} minHeight={70} minWidth={600}></ModalTable>
      </BodyContent>
    </>
  );
};

const ConfirmSubmitRequestModalFooterContent = ({ onConfirmRequestFn, updateStatusButtonText }) => {
  // context(s)
  const globalModalContext = React.useContext(GlobalModalContext);

  // function(s)
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });

  return (
    <>
      <div className="d-flex">
        <WBCPrimaryButton
          width={200}
          onClick={event => {
            event.stopPropagation();
            onConfirmRequestFn();
            onClose();
          }}
        >
          {updateStatusButtonText ? updateStatusButtonText : "Submit Request to BOC"}
        </WBCPrimaryButton>
        <div className="pl-2">
          <WBCPrimaryOutlinedButton
            width={142}
            onClick={event => {
              event.stopPropagation();
              onClose();
            }}
          >
            Back
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </>
  );
};

ConfirmSubmitRequestModalContent.propTypes = {
  content: PropTypes.string,
  submitProfile: PropTypes.any
};

ConfirmSubmitRequestModalFooterContent.propTypes = {
  onConfirmRequestFn: PropTypes.func,
  updateStatusButtonText: PropTypes.string
};

ConfirmSubmitRequestModalContent.displayName = "BodyContent";
ConfirmSubmitRequestModalFooterContent.displayName = "FooterContent";

export { ConfirmSubmitRequestModalContent, ConfirmSubmitRequestModalFooterContent };

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CoachingStaffTable from "./CoachingStaffTable";
import WBCCollapse from "../elements/WBCCollapse";

const CoachingStaffSectionSubtitle = styled.p`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-left: 0.5rem;
  color: ${props => props.theme["dark-grey"]};
  &.min-not-met {
    color: ${props => props.theme["mlb-red"]};
  }
`;

const CoachingStaffSection = ({ staff, isBOC, personnel }) => {
  const title = (
    <div className="d-flex flex-row">
      {staff?.type}
      <CoachingStaffSectionSubtitle className={personnel?.length < staff?.min ? "min-not-met" : ""}>
        minimum - {staff?.min}
      </CoachingStaffSectionSubtitle>
    </div>
  );

  return (
    <WBCCollapse title={title} startsOpen={true} className="py-1">
      <CoachingStaffTable isBOC={isBOC} data={personnel} maxRows={staff?.max || 2} />
    </WBCCollapse>
  );
};

CoachingStaffSection.propTypes = {
  staff: PropTypes.object.isRequired,
  isBOC: PropTypes.bool.isRequired,
  personnel: PropTypes.array.isRequired
};

export default CoachingStaffSection;

import React, { useCallback, useContext, useRef, useState } from "react";
import { Input } from "best-common-react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import LabelConstants from "../../../constants/LabelConstants";
import PositionConstants from "../../../constants/PositionConstants";
import RouteConstants from "../../../constants/RouteConstants";
import AuthContext from "../../../contexts/AuthContext";
import FinalizedStatusContext from "../../../contexts/FinalizedStatusContext";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import OrgsContext from "../../../contexts/OrgsContext";
import RosterContext from "../../../contexts/RosterContext";
import TournamentFormContext from "../../../contexts/TournamentFormContext";
import openDocumentsModal from "../../../effects/documents/openDocumentsModal";
import { getHasNppa, getHasOtherDocs } from "../../../selectors/DocumentSelectors";
import { addToList, removeFromList } from "../../../utils/DragUtils";
import { properNoun } from "../../../utils/StringUtils";
import { ContextMenu, MenuItem } from "../../elements/ContextMenu";
import DisabledInputStyle from "../../elements/DisabledInputStyle";
import DownloadDocumentsButton from "../../elements/DownloadDocumentsButton";
import WBCAccessControl from "../../protected/WBCAccessControl";
import WBCAdminAccess from "../../protected/WBCAdminAccess";
import NonCurrentYearAccess from "../../protected/NonCurrentYearAccess";
import CellText from "../components/CellText";
import LinkText from "../components/LinkText";
import { BoldText, DraggableRow, DragIcon, ListText, VerticalEllipses } from "./PersonnelHelpers";

const Row = styled(DraggableRow)`
  height: ${props => (props.isDragging ? undefined : "40px")};
  background-color: ${props => (props.isDragging ? "#cbe2f9" : undefined)};

  @media (max-width: 767px) {
    height: auto;
    min-height: 35px;
  }
`;

const Cell = styled.div`
  display: flex;
  padding: 8px;
  position: relative;
  width: ${props => (props.width ? `${props.width}px` : undefined)};
  min-width: ${props => (props.width ? `${props.width}px` : undefined)};
`;

const NPPACell = styled(Cell)`
  padding: 0 8px;
`;

const IconCell = styled(Cell)`
  justify-content: space-between;
`;

const DragBars = styled(DragIcon)`
  cursor: auto;
  margin: 0;
`;

const Ellipsis = styled(VerticalEllipses)`
  display: ${props => (props.isHidden ? "none" : undefined)};
`;

const NPPA = styled(DownloadDocumentsButton)`
  margin: auto;
  &&& {
    padding: 0;
  }
`;

const UniformInput = styled(Input)`
  &&& {
    height: 28px;
    font-size: 14px;
  }
`;

const PersonnelRow = ({ droppableId, row: profile, index, pixelWidth }) => {
  // hooks
  const targetRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const orgsContext = useContext(OrgsContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);

  // variable(s)
  const dragId = `${droppableId}-${profile.profileId}`;
  const listType = droppableId.replace("-list", "");
  const { isBOCadmin } = authContext.state;
  const { onFormDirty } = tournamentFormContext.state;
  const { idToDataMap, personnel } = rosterContext.state;
  const { finalized } = finalizedStatusContext.state;

  // dispatches
  const { dispatch: rosterDispatch } = rosterContext;
  const { dispatch: globalModalDispatch } = useContext(GlobalModalContext);

  // functions
  const toggleMenu = useCallback(value => setIsOpen(value !== undefined ? value : !isOpen), [isOpen, setIsOpen]);

  const removePersonnel = () => {
    const source = idToDataMap[droppableId];
    // remove from this list, and put it in the staff list
    rosterDispatch({
      type: "updateMultiple",
      key: "personnel",
      data: {
        [source]: removeFromList(personnel[source], index),
        staff: addToList(personnel.staff, profile)
      }
    });
    toggleMenu(false);
    onFormDirty();
  };

  const onDocumentsSaveSuccess = useCallback(
    documentsState => {
      rosterDispatch({
        type: "setPlayerDocFlags",
        profileId: documentsState.profile.profileId,
        hasPpa: false,
        hasOtherDocs: getHasOtherDocs(documentsState),
        hasNppa: getHasNppa(documentsState)
      });
    },
    [rosterDispatch]
  );

  const onNPPAUploadClicked = useCallback(() => {
    openDocumentsModal({
      profile,
      labelTypeId: LabelConstants.LABEL_TYPE.STAFF,
      globalModalDispatch,
      onSaveSuccess: onDocumentsSaveSuccess
    });
    toggleMenu(false);
  }, [profile, globalModalDispatch, toggleMenu, onDocumentsSaveSuccess]);

  return (
    <WBCAccessControl
      noDiv
      input={isDragDisabled => (
        <Draggable
          isDragDisabled={isDragDisabled || (finalized && !isBOCadmin) || isOpen}
          draggableId={dragId}
          index={index}
        >
          {(provided, snapshot) => {
            const { isDragging } = snapshot;
            const { innerRef, draggableProps, dragHandleProps } = provided;
            const width = isDragging || !pixelWidth ? undefined : pixelWidth;

            return (
              <Row id={dragId} isOpen={isOpen} isDragging={isDragging} ref={innerRef} {...draggableProps}>
                <IconCell width={70}>
                  {isDragging ? null : <BoldText>{index + 1}</BoldText>}
                  {!finalized || isBOCadmin ? (
                    <WBCAdminAccess>
                      <Ellipsis ref={targetRef} isHidden={isDragging} onClick={() => setIsOpen(!isOpen)} />
                      <DragBars {...dragHandleProps} />
                      <ContextMenu
                        targetRef={targetRef}
                        isOpen={isOpen}
                        toggleMenu={toggleMenu}
                        offsetX={-23}
                        offsetY={9}
                      >
                        <MenuItem onClick={removePersonnel}>
                          <i className="fas fa-trash-alt fa-lg"></i>
                          {`remove ${listType}`}
                        </MenuItem>
                        <MenuItem onClick={onNPPAUploadClicked}>
                          <i className="fas fa-file-upload fa-lg"></i>NPPA
                        </MenuItem>
                      </ContextMenu>
                    </WBCAdminAccess>
                  ) : null}
                </IconCell>
                <Cell width={width}>
                  <LinkText
                    row={profile}
                    profileType={RouteConstants.STAFF}
                  >{`${profile.fullName.lastName}, ${profile.fullName.firstName}`}</LinkText>
                </Cell>
                <Cell width={120}>
                  <CellText>{PositionConstants.POSITIONS[profile.stats.positionId] || properNoun(listType)}</CellText>
                </Cell>
                <NonCurrentYearAccess>
                  <NPPACell width={50}>
                    {profile.hasNppa ? (
                      <NPPA
                        profileId={profile.profileId}
                        teamId={profile.fedTeamId}
                        docLabelIds={[LabelConstants.LABEL.NPPA]}
                      />
                    ) : null}
                  </NPPACell>
                </NonCurrentYearAccess>
                {isDragging ? null : (
                  <>
                    <Cell width={82}>
                      <ListText>
                        <WBCAccessControl
                          input={isDisabled => (
                            <UniformInput
                              inputStyle={DisabledInputStyle}
                              value={(profile.uniform && profile.uniform.uniformNumber) || ""}
                              disabled={isDisabled || (!isBOCadmin && finalized)}
                              onChange={event => {
                                rosterDispatch({
                                  type: "setUniform",
                                  field: "uniformNumber",
                                  profileId: profile.profileId,
                                  value: event.target.value
                                });
                                onFormDirty();
                              }}
                            />
                          )}
                        />
                      </ListText>
                    </Cell>
                    <Cell width={67}>
                      <ListText>
                        <WBCAccessControl
                          input={isDisabled => (
                            <UniformInput
                              inputStyle={DisabledInputStyle}
                              value={(profile.uniform && profile.uniform.pants) || ""}
                              disabled={isDisabled || (!isBOCadmin && finalized)}
                              onChange={event => {
                                rosterDispatch({
                                  type: "setUniform",
                                  field: "pants",
                                  profileId: profile.profileId,
                                  value: event.target.value
                                });
                                onFormDirty();
                              }}
                            />
                          )}
                        />
                      </ListText>
                    </Cell>
                    <Cell width={67}>
                      <ListText>
                        <WBCAccessControl
                          input={isDisabled => (
                            <UniformInput
                              inputStyle={DisabledInputStyle}
                              value={(profile.uniform && profile.uniform.jersey) || ""}
                              disabled={isDisabled || (!isBOCadmin && finalized)}
                              onChange={event => {
                                rosterDispatch({
                                  type: "setUniform",
                                  field: "jersey",
                                  profileId: profile.profileId,
                                  value: event.target.value
                                });
                                onFormDirty();
                              }}
                            />
                          )}
                        />
                      </ListText>
                    </Cell>
                    <Cell width={75}>
                      <ListText>
                        <WBCAccessControl
                          input={isDisabled => (
                            <UniformInput
                              inputStyle={DisabledInputStyle}
                              value={(profile.uniform && profile.uniform.hat) || ""}
                              disabled={isDisabled || (!isBOCadmin && finalized)}
                              onChange={event => {
                                rosterDispatch({
                                  type: "setUniform",
                                  field: "hat",
                                  profileId: profile.profileId,
                                  value: event.target.value
                                });
                                onFormDirty();
                              }}
                            />
                          )}
                        />
                      </ListText>
                    </Cell>
                    <Cell width={67}>
                      <CellText>
                        {profile.affiliation ? orgsContext.state.orgIdToCode[profile.affiliation.orgId] : ""}
                      </CellText>
                    </Cell>
                    <Cell width={width}>
                      <CellText>{profile.affiliation ? profile.affiliation.clubString : ""}</CellText>
                    </Cell>
                  </>
                )}
              </Row>
            );
          }}
        </Draggable>
      )}
    />
  );
};

PersonnelRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  pixelWidth: PropTypes.number,
  droppableId: PropTypes.string
};

export default PersonnelRow;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  height: 32px;
  padding-left: 13px;
  font-family: Helvetica;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: normal;
  background-color: ${props => props.theme["almost-white"]};
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
  color: ${props => props.theme["dark-grey"]};
`;

const Minimum = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${({ invalid, theme }) => (invalid ? theme.wbcRed : theme["dark-grey"])};
`;

const DraggableRow = styled.div`
  display: flex;
  min-height: 35px;
  border-bottom: 1px solid #ececec;

  :last-child {
    border-bottom: none;
  }

  :focus {
    outline: none;
  }

  background-color: ${({ isOpen }) => (isOpen ? "#f2f8fe" : undefined)};
  box-shadow: ${({ isOpen, isDragging }) => (isOpen || isDragging ? "0 1px 4px 0 rgba(0, 0, 0, 0.2)" : undefined)};
`;

const IconContainer = styled.div`
  cursor: pointer;
  color: ${props => (props.disabled ? props.theme.grey : props.theme.wbcBlue)};

  :focus {
    outline: none;
  }

  &&& {
    i {
      font-size: 1.32em;
    }
  }
`;

const EllipsisContainer = styled(IconContainer)`
  -webkit-text-stroke: 1px #ffffff;
`;

const DragIconContainer = styled(IconContainer)`
  cursor: grab;
  display: inline-block;
  margin: 0 8px;
`;

const ListText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme.black};
`;

const BoldText = styled(ListText)`
  font-weight: bold;
  color: ${props => props.theme["dark-grey"]};
`;

const PersonnelHeader = ({ className, text, minimum, invalid }) => (
  <Header className={className}>
    <HeaderText>{text}</HeaderText>
    <Minimum invalid={invalid}>{`minimum - ${minimum}`}</Minimum>
  </Header>
);

const VerticalEllipses = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  return (
    <EllipsisContainer className={className} ref={ref} {...rest}>
      <i className="fas fa-ellipsis-v fa-lg"></i>
    </EllipsisContainer>
  );
});

const DragIcon = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  return (
    <DragIconContainer ref={ref} className={className} {...rest}>
      <i className="fas fa-grip-lines fa-lg"></i>
    </DragIconContainer>
  );
});

PersonnelHeader.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  minimum: PropTypes.number,
  invalid: PropTypes.bool
};

VerticalEllipses.propTypes = {
  className: PropTypes.string
};

DragIcon.propTypes = {
  className: PropTypes.string
};

VerticalEllipses.displayName = "VerticalEllipses";
DragIcon.displayName = "DragIcon";

export { PersonnelHeader, DraggableRow, VerticalEllipses, DragIcon, ListText, BoldText };

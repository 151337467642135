import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import StaffList from "./provisional/StaffList";
import Personnel from "./provisional/Personnel";
import PlayerTable from "./provisional/PlayerTable";
import RosterContext from "../../contexts/RosterContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import InnerCollapse from "../elements/InnerCollapse";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";

const Container = styled.div`
  margin-bottom: 21px;
`;

const TableContainer = styled.div.attrs(() => ({
  className: "row no-gutters"
}))`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 47px;
`;

const ProvisionalCollapse = styled(WBCCollapse)`
  .wbc-collapse-subtitle {
    color: ${({ invalid, theme }) => (invalid ? theme.wbcRed : undefined)};
  }
`;

const ProvisionalRoster = () => {
  // context(s) && state
  const rosterContext = useContext(RosterContext);
  const { selectedWbcYear, selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const tournamentType = useContext(TournamentTypeContext);

  // variable(s)
  const { teamId: fedTeamId } = selectedTeamContext.state.team;
  const { length: size } = rosterContext.state.provisional.players;
  const { provisionalIsOpen, staffIsOpen, playersIsOpen } = rosterContext.state;
  const { dispatch: rosterDispatch } = rosterContext;
  const settings = (selectedWbcYear && selectedWbcYear[`${tournamentType}TournamentSettings`]) || null;
  const provisionalMax = settings && settings.rosterNumbers.provisionalMax;

  const { sortFilters } = tableSortFilterContext.state;

  // function(s)
  const exportFn = useCallback(() => {
    ExportApi.exportProvisionalRosterList(
      selectedYear,
      fedTeamId,
      tournamentType,
      sortFilters.col,
      sortFilters.dir,
      (url, headers) => ({
        url,
        headers
      })
    ).then(data => {
      const test = new Blob([data.url], { type: data.headers["content-type"] });
      const url = window.URL.createObjectURL(test);
      FileHelper.downloadFile(url, data.headers);
    });
  }, [sortFilters, selectedYear, fedTeamId, tournamentType]);

  const clickedStaffCollapse = isOpen => {
    rosterDispatch({
      type: "setStaffIsOpen",
      isOpen
    });
  };

  const clickedPlayersCollapse = isOpen => {
    rosterDispatch({
      type: "setPlayersIsOpen",
      isOpen
    });
  };

  const clickedProvisionalCollapse = isOpen => {
    rosterDispatch({
      type: "setProvisionalIsOpen",
      isOpen
    });
  };

  return (
    settings && (
      <Container>
        <ProvisionalCollapse
          exportable
          startsOpen={provisionalIsOpen || staffIsOpen || playersIsOpen}
          openCloseCallback={clickedProvisionalCollapse}
          title={`Provisional (${size})`}
          subTitle={`${provisionalMax} player max`}
          invalid={size > provisionalMax}
          exportFn={exportFn}
        >
          <InnerCollapse title="Players" openCloseCallback={clickedPlayersCollapse} startsOpen={playersIsOpen}>
            <PlayerTable />
          </InnerCollapse>
        </ProvisionalCollapse>
      </Container>
    )
  );
};

export default ProvisionalRoster;

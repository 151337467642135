import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useCellResize } from "../../hooks/useCellResize";
import NonCurrentYearAccess from "../../protected/NonCurrentYearAccess";
import PersonnelList from "./PersonnelList";

const Container = styled.div`
  width: 100%;
  min-width: 400px;
  overflow-x: auto;
`;

const Header = styled.div`
  height: 24px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => props.theme["wbcRed"]};
`;

const Categories = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  margin-bottom: 1px;
  background-color: ${props => props.theme["almost-white"]};
`;

const CategoryTh = styled.div`
  padding: 8px;
  height: 32px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
  width: ${props => (props.width ? `${props.width}px` : undefined)};
  min-width: ${props => (props.width ? `${props.width}px` : undefined)};
`;

const TrainerList = styled(PersonnelList)`
  border-bottom: 1px solid #ececec;
`;

const getLimits = settings => {
  const result = {
    minimum: 0,
    maximum: 0
  };
  Object.keys(settings).forEach(key => {
    const arg = key.toLowerCase();
    if (arg.includes("min")) {
      result.minimum = settings[key];
    } else if (arg.includes("max")) {
      result.maximum = settings[key];
    }
  });
  return result;
};

const MIN_COLUMN_WIDTH = 70;

const Personnel = ({ settings }) => {
  const id = "personnel-columns";
  const pixelWidth = useCellResize(id, 2, 598, MIN_COLUMN_WIDTH);

  return (
    <Container>
      <Header>A NPPA must be attached for each manager, coach and trainer</Header>
      <Categories id={id}>
        <CategoryTh width={70} />
        <CategoryTh width={pixelWidth}>Name</CategoryTh>
        <CategoryTh width={120}>Pos</CategoryTh>
        <NonCurrentYearAccess>
          <CategoryTh width={50}>NPPA</CategoryTh>
        </NonCurrentYearAccess>
        <CategoryTh width={82}>Uniform #</CategoryTh>
        <CategoryTh width={67}>Pants</CategoryTh>
        <CategoryTh width={67}>Jersey</CategoryTh>
        <CategoryTh width={75}>Hat</CategoryTh>
        <CategoryTh width={67}>Org</CategoryTh>
        <CategoryTh width={pixelWidth}>Club</CategoryTh>
      </Categories>
      <PersonnelList droppableId="manager-list" header="Managers" settings={getLimits(settings.managers)} />
      <PersonnelList droppableId="coach-list" header="Coaches" settings={getLimits(settings.coaches)} />
      <TrainerList droppableId="trainer-list" header="Trainers" settings={getLimits(settings.trainers)} />
    </Container>
  );
};

Personnel.propTypes = {
  settings: PropTypes.object
};

export default Personnel;
